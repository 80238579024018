Vue.component('spark-update-heatmap-defaults', {
    props: ['team'],
    data: function data() {
        return {
            isLoading: true,
            form: new SparkForm({
                grid_size: '9x9',
                unit: 'miles',
                point_radius: '0.75'
            })
        };
    },

    created() {
        axios.get('/heatmap-defaults/' + this.team.id)
            .then(response => {
                // Update the form data if there's any default setting stored in the DB
                this.form.auto = !!response.data.auto;
                if (response.data.grid_size) this.form.grid_size = response.data.grid_size;
                if (response.data.unit) this.form.unit = response.data.unit;
                if (response.data.point_radius) this.form.point_radius = response.data.point_radius;
                this.isLoading = false;
            })
            .catch(error => {
                console.error('Error fetching heatmap defaults:', error);
            })
            .finally(() => {
                this.isLoading = false;  // Ensure this runs regardless of success or failure
            });
        },

    methods: {
        update() {
            axios.post('/update-heatmap-defaults/' + this.team.id, this.form)
                .then(response => {
                    // Handle success e.g. show a success message
                    this.form.successful = true;
                })
                .catch(error => {
                    // Handle errors e.g. set this.form.errors
                    this.form.errors.set(error.response.data.errors);
                });
        }
    }
});
