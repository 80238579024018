var base = require('auth/register-stripe');

Vue.component('spark-register-stripe', {
    //  created() {
    //    alert('created?');
    //     this.getPlans();

    //     this.query = URI(document.URL).query(true);

    //     if (this.query.invitation) {
    //         this.getInvitation();
    //         this.registerForm.invitation = this.query.invitation;
    //     }
    // },
    /**
     * The component's data.
     */
    // data: function() {
    //     return {
    //         query: null,
    //         country: null,
    //         taxRate: 0,

    //         registerForm: $.extend(true, new SparkForm({
    //             stripe_token: 'xxx',
    //             plan: '',
    //             team: '',
    //             team_slug: '',
    //             name: '',
    //             email: '',
    //             password: '',
    //             password_confirmation: '',
    //             vat_id: '',
    //             terms: false,
    //             coupon: null,
    //             invitation: null
    //         }), Spark.forms.register)

    //     };
    // },
    mixins: [base],
    // methods: {

    //     register() {

    //         this.registerForm.busy = true;
    //         this.registerForm.errors.forget();

    //         this.registerForm.stripe_token = 'tok_visa';
            
    //         Spark.post('/register', this.registerForm)  
    //             .then(response => {
    //                 window.location = response.redirect;
    //             });
     
    //         }
    // },
   
});

