var base = require('settings/subscription/cancel-subscription');

// define a mixin object
var emailSupport = {
  created: function () {
    
  },
  methods: {
    emailSupport: function () {
      $('#modal-support').modal('show');
    }
  }
}

Vue.component('spark-cancel-subscription', {
    mixins: [base,emailSupport]
});
