
module.exports = {
    props: [],
	data: function data() {
        return {
            UPRATED: null,
            activeDialog: 'rateus',
            form: new SparkForm({
                information: ''
            })
        };
    },

    /**
     * Load mixins for the component.

     mixins: [require('../../../../vendor/laravel/spark/resources/assets/js/mixins/tab-state')],
	*/
 	mounted() {
        this.form.information = '';

            var hash = window.location.hash.substring(2);
            var parameters = hash.split('/');
            hash = parameters.shift();

            if(hash.length > 0)
            {
                if(hash == 'yes')
                {
                    this.rateYes();
                    this.activeDialog='reviews';
                    this.redirectReview();
                }
                else if(hash == 'no')
                {
                    this.rateNo();
                    this.activeDialog='feedback';
                }
                else
                    this.activeDialog = hash;

            }
    },

    methods: {

        rateYes() {
            var rateurl = '/rating/yes/' + Spark.rhash + '/' + Spark.listing_id + '/' + Spark.review_request_id;
            $.ajax({url: rateurl, success: function(result){
                console.log('rating: 1 / ' + Spark.review_request_id);
            }});
            this.activeDialog='reviews';
            this.redirectReview();
        },
        rateNo() {
            var rateurl = '/rating/no/' + Spark.rhash + '/' + Spark.listing_id + '/' + Spark.review_request_id;
            $.ajax({url: rateurl, success: function(result){
                console.log('rating: 0 / ' + Spark.review_request_id);
            }});
            this.activeDialog='feedback';

        },
        redirectReview() {

             setTimeout(function(){ console.log('redirecting'); location.href=Spark.publishers.reviewposturl; }, 1000);
        },
        skipFeedback() {

            setTimeout(function(){ console.log('redirecting'); location.href=Spark.publishers.reviewposturl; }, 3000);
       },
        sendFeedback() {

            var fbForm = new SparkForm({
                listing_id: Spark.listing_id,
                review_request_id: Spark.review_request_id,
                rhash: Spark.rhash,
                body: $('#feedback-text').val()
            });

            $('#fb-submit').attr('disabled','');

            var self=this;

            Spark.post('/rating/feedback', fbForm)
                .then(response => {
                    self.activeDialog='feedback-sent';
                })
                .catch(response => {
                    console.log("fail");
                });

            //console.log($('#feedback-form').serializeArray());
            //Spark.post('/rating/feedback', this.form);
            //$.post('/rating/feedback', $('#feedback-form').serializeArray(), function(data) {  this.feedbackSent(); });
        }
    }

};
